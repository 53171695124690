//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
export default {
  head() {
    return {
      ...this.seoInfo["404"],
    };
  },
  computed: {
    ...mapGetters(["seoInfo"]),
  },
};
